import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import "./certifications.scss";
import frontEnd from "../../../utils/img/certifications/Verified Certificate_Front End Development Track.jpg";
import professional from "../../../utils/img/certifications/Front End Web Development Professional certification.jpg";
import advanced from "../../../utils/img/certifications/Advanced Front-End Web Development certificate.jpg";
import fullStack from "../../../utils/img/certifications/Certificate_Udacity_Full Stack Development Track.jpg";
import Gallery from "../Gallery";

const Certifications = () => {
  const certPhotos = [
    { img: frontEnd, name: "frontEnd", details: "" },
    { img: professional, name: "professional" },
    { img: advanced, name: "advanced" },
    { img: fullStack, name: "fullStack" },
  ];

  return (
    <div className="section certifications" id="certifications">
      <Gallery items={certPhotos} galleryName="Certifications" />
    </div>
  );
};

export default Certifications;
