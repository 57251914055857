import React, { useEffect, useState } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import "./website.scss";
import { getData } from "./sitesData";
const Website = () => {
  const params = useParams();
  const [siteInfo, setSiteInfo] = useState();
  useEffect(() => {
    setSiteInfo(getData(params.websiteName));
  }, [params.websiteName]);

  return (
    <div className="site-container">
      {siteInfo ? (
        <Container>
          <h1>{siteInfo.name}</h1>
          <Row className="site-details">
            <Col xs="12" md="8" lg="6">
              {/* <div className="img-container">
                <div className="block">
                  <div
                    className="side -main"
                    style={{
                      backgroundImage: `url(${siteInfo.img})`,
                    }}
                  ></div>
                  <div
                    className="side -left"
                    style={{ backgroundImage: `url(${siteInfo.img})` }}
                  ></div>

                </div>
              </div> */}
              <div className="img-container">
                <div className="img-paper">
                  <div
                    className="part left"
                    style={{
                      backgroundImage: `url(${siteInfo.img})`,
                    }}
                  ></div>
                  <div
                    className="part mid-left"
                    style={{
                      backgroundImage: `url(${siteInfo.img})`,
                    }}
                  ></div>
                  <div
                    className="part mid-right"
                    style={{ backgroundImage: `url(${siteInfo.img})` }}
                  ></div>
                  <div
                    className="part right"
                    style={{ backgroundImage: `url(${siteInfo.img})` }}
                  ></div>
                </div>
              </div>
            </Col>
            <Col xs="12">
              <h2>about</h2>
              <p>{siteInfo.about}</p>
              {siteInfo.details && (
                <>
                  <h3>details</h3>
                  <ul>
                    {siteInfo.details.map((item) => (
                      <li key={item}>{item}</li>
                    ))}
                  </ul>
                </>
              )}
            </Col>
            <Col xs="12">
              <h2>technologies</h2>
              {siteInfo.technologies?.frontEnd?.length > 1 && (
                <>
                  <h3>front-end </h3>
                  <div>
                    {siteInfo.technologies.frontEnd.map((tech) => (
                      <p className="tech front-end" key={tech}>
                        {tech}
                      </p>
                    ))}
                  </div>
                </>
              )}
              {siteInfo.technologies?.backEnd?.length > 1 && (
                <>
                  <h3>back-end</h3>
                  <div>
                    {siteInfo.technologies.backEnd.map((tech) => (
                      <p className="tech" key={tech}>
                        {tech}
                      </p>
                    ))}
                  </div>
                </>
              )}
            </Col>
            <Col xs="12">
              <h2>link</h2>
              <a href={siteInfo.address} target="_blank" rel="noreferrer">
                {siteInfo.address}
              </a>
            </Col>
          </Row>
        </Container>
      ) : (
        <div>loading</div>
      )}
    </div>
  );
};

export default Website;
