import React, { useRef, useState } from "react";
import { Button, Container, OverlayTrigger, Popover } from "react-bootstrap";
import left from "../../utils/img/angle-left-solid.svg";
import right from "../../utils/img/angle-right-solid.svg";
import "./gallery.scss";
import { Link } from "react-router-dom";

const Gallery = ({ items, galleryName }) => {
  const scrollRef = useRef();
  const [scrollPosition, setScrollPosition] = useState("start");
  const detectScroll = () => {
    scrollRef.current.scrollLeft === 0
      ? setScrollPosition("start")
      : scrollRef.current.scrollLeft + scrollRef.current.offsetWidth >=
        scrollRef?.current?.scrollWidth
      ? setScrollPosition("end")
      : setScrollPosition("scrolling");
  };

  return (
    <Container fluid className="main-container section-card">
      <h1 className="gallery-header topic">{galleryName}</h1>
      <div
        className="scrolling"
        ref={scrollRef}
        onScrollCapture={() => detectScroll()}
      >
        {items?.map((item) => (
          <div className="item-container" key={item.name}>
            <div className="subcontainer">
              {item?.details && (
                <div className="details-paragraph">
                  {item.details}
                  <Link to={`/websiteDetails/${item.name}`}>more Detais</Link>
                </div>
              )}{" "}
              <img
                src={item.img}
                alt={item.name}
                className="item"
                style={{ maxHeight: scrollRef?.current?.offsetHeight }}
              />
            </div>
          </div>
        ))}
      </div>{" "}
      <div className="scroll-arrows">
        <button
          className="left"
          onClick={(e) =>
            (scrollRef.current.scrollLeft -= scrollRef?.current?.offsetWidth)
          }
          disabled={scrollPosition === "start"}
        >
          <img className="arrow-icon left-arrow" src={left} alt="left" />
        </button>
        <button
          className="right"
          onClick={(e) => {
            scrollRef.current.scrollLeft += scrollRef?.current?.offsetWidth;
          }}
          disabled={scrollPosition === "end"}
        >
          <img className="arrow-icon right-arrow" src={right} alt="right" />{" "}
        </button>
      </div>
    </Container>
  );
};

export default Gallery;
