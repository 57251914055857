import React, { useRef, useState } from "react";
import {
  Alert,
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Spinner,
} from "react-bootstrap";
import "./contact.scss";
import myPhoto from "../../../utils/img/elashry15.png";
import emailjs from "@emailjs/browser";
import whatsappIcon from "../../../utils/img/whatsapp.svg";
import phoneIcon from "../../../utils/img/phone-solid.svg";
import emailIcon from "../../../utils/img/envelope-solid.svg";
import closeIcon from "../../../utils/img/xmark-solid.svg";

const Contact = () => {
  const formRef = useRef();
  const [alertMessage, setAlertMessage] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [assistantType, setAssistantType] = useState("");
  const [toggleAssist, setToggleAssist] = useState(false);

  const handleClosePopover = (e) => {
    e.preventDefault();
    setAssistantType("secondary-assistant");
    setTimeout(() => {
      setToggleAssist(true);
    }, 2000);
  };
  const handleOpenPopover = (e) => {
    e.preventDefault();
    setAssistantType("primary-assistant");
    setTimeout(() => {
      setToggleAssist(false);
    }, 1000);
  };
  const sendMessage = (e) => {
    e.preventDefault();
    setAlertMessage(null);
    setSubmitting(true);
    emailjs
      .sendForm(
        "service_gzp807d",
        "template_7wtlicl",
        formRef.current,
        "kLDBje0HfW88ESFiM"
      )
      .then(
        (result) => {
          result.text.toLowerCase() === "ok"
            ? setAlertMessage("ok")
            : setAlertMessage(result.text);
          setSubmitting(false);
        },
        (error) => {
          error.text
            ? setAlertMessage(error.text)
            : setAlertMessage(
                "Sorry,something went wrong. please check your internet connection."
              );
          setSubmitting(false);
          console.log(error.text);
        }
      );
  };
  return (
    <div className="section contact" id="contact">
      <div
        style={{ position: "absolute" }}
        className={`assistant-container ${assistantType}`}
      >
        {!toggleAssist ? (
          <div className="popover-assist">
            <div className="popover-assist-container">
              <div>
                <img src={myPhoto} alt="" className="popover-assist-photo" />
              </div>
              <div className="popover-card">
                <div className="popover-text">
                  <h5 className="popover-topic">You can also contact me by</h5>
                  <Button
                    variant="dark"
                    className="close-btn"
                    onClick={(e) => handleClosePopover(e)}
                  >
                    <img className="close-icon" src={closeIcon} alt="close" />
                  </Button>

                  <div className="icon-div">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="email:elashryn3@gmail.com"
                    >
                      <img
                        className="contact-icons"
                        src={emailIcon}
                        alt="email"
                      />
                    </a>
                    <label className="icon-label"> elashryn3@gmail.com</label>
                  </div>
                  <div className="icon-div">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://wa.me/message/PCOA3IJRRJCJB1"
                    >
                      <img
                        className="contact-icons"
                        src={whatsappIcon}
                        alt="whatsapp"
                      />
                    </a>
                    <label className="icon-label"> +201033899485 </label>
                  </div>
                  <div className="icon-div">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="tel:+201033899485"
                    >
                      <img
                        className="contact-icons"
                        src={phoneIcon}
                        alt="phone"
                      />
                    </a>
                    <label className="icon-label"> +201033899485 </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="ball-assist" onClick={(e) => handleOpenPopover(e)}>
            <div className="ball-content">
              <p className="ball-message">Get in touch</p>
              <p className="ball-message">click here</p>
            </div>
          </div>
        )}
      </div>

      <Container fluid className="section-card contact-card">
        <h1 className="topic">Contact</h1>
        <Card body className="contact-form">
          <Form
            ref={formRef}
            onSubmit={(e) => sendMessage(e)}
            onChange={(e) => setAlertMessage(null)}
          >
            <Row>
              <Col md>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Control
                    type="text"
                    name="first_name"
                    placeholder="First name"
                    required
                  />
                </Form.Group>
              </Col>

              <Col md>
                {" "}
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Control
                    type="text"
                    name="last_name"
                    placeholder="Last name"
                    required
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={8}>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Control
                    type="email"
                    name="email"
                    placeholder="name@example.com"
                    required
                  />
                </Form.Group>
              </Col>
              <Col md={8}>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="message"
                    name="message"
                    rows={3}
                    required
                  />
                </Form.Group>
              </Col>
              {alertMessage && (
                <Col xs={12}>
                  {alertMessage === "ok" ? (
                    <Alert variant="success">
                      Your message has been recieved , I will respond to your
                      message asap.Thank you
                    </Alert>
                  ) : (
                    <Alert variant="danger">{alertMessage}</Alert>
                  )}
                </Col>
              )}
              <Col xs={12}>
                <Button
                  type="submit"
                  className="submit-btn"
                  variant="secondary"
                  disabled={submitting}
                >
                  {submitting ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : (
                    "Submit"
                  )}
                </Button>
              </Col>
            </Row>
          </Form>
        </Card>
      </Container>
    </div>
  );
};

export default Contact;
