import "./App.scss";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./components/main/Home";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import Website from "./components/main/websites/Website";
import NotFound from "./components/main/notFound/NotFound";
import { useRef, useState } from "react";
import { useOnLoadImages } from "./components/hooks/useOnloadImages";
import { Spinner } from "react-bootstrap";

function App() {
  const [activeSection, setActiveSection] = useState("");
  const wrapperRef = useRef(null);
  const imagesLoaded = useOnLoadImages(wrapperRef);

  return (
    <div className="App" ref={wrapperRef}>
      {!imagesLoaded && (
        <div className="loading">
          <Spinner
            animation="border"
            variant="info"
            className="spinner-loading"
          />
        </div>
      )}
      <Router>
        <Header activeSection={activeSection} />
        <main>
          <Routes>
            <Route
              path="/"
              element={<Home setActiveSection={setActiveSection} />}
            />
            <Route path="/websiteDetails/:websiteName" element={<Website />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </main>
        {/* <Footer /> */}
      </Router>
    </div>
  );
}

export default App;
