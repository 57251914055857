import React from "react";
import "./portfolio.scss";
import cvBuilder from "../../../utils/img/websites/cvBuilder4.png";
import xoChat from "../../../utils/img/websites/xoChat4.png";
import Gallery from "../Gallery";

const Portfolio = () => {
  const cvBuilderDetails = (
    <p>
      <span style={{ color: "red", fontWeight: "bold" }}>cvBuilder </span> is a
      web application helps to build your resume and download it easly
    </p>
  );
  const xoChatDetails = (
    <p>
      <span style={{ color: "red", fontWeight: "bold" }}>xo-chat </span>app is a
      web application for chatting ,voice calls and video calls
    </p>
  );

  const websitesScreenshoots = [
    {
      img: cvBuilder,
      name: "cvBuilder",
      details: cvBuilderDetails,
    },
    {
      img: xoChat,
      name: "xoChat",
      details: xoChatDetails,
    },
  ];
  return (
    <div className="section portfolio" id="portfolio">
      <Gallery items={websitesScreenshoots} galleryName="Portfolio" />
    </div>
  );
};

export default Portfolio;
