import React from "react";
import { Col, Image, Row } from "react-bootstrap";
import "./intro.scss";
import Typewriter from "typewriter-effect";
import myPhoto from "../../../utils/img/elashry15.png";
import motion01 from "../../../utils/img/coding/png/motion01.png";
import motion02 from "../../../utils/img/coding/png/motion02.png";
import motion03 from "../../../utils/img/coding/png/motion06.png";
import motion05 from "../../../utils/img/coding/png/motion11_1.png";
import motion06 from "../../../utils/img/coding/png/motion11_2.png";

const Intro = () => {
  return (
    <div className="section intro" id="intro">
      <div fluid className="intro-card section-card">
        <Row className="content">
          <Row className="animation-row">
            <Col xs={6} md={3} className="animation-col">
              <Image className="animation-bg4" src={motion03} />
            </Col>
            <Col xs={6} md={3} className="animation-col">
              <Image className="animation-bg5" src={motion05} />
              <Image className="animation-bg6" src={motion06} />
            </Col>
            <Col xs={6} md={3} className="animation-col">
              <Image className="animation-bg2" src={motion02} />
              <Image className="animation-bg3" src={motion02} />
            </Col>
            <Col xs={6} md={3} className="animation-col">
              <Image className="animation-bg" src={motion01} />
            </Col>
          </Row>
          <Col xs={3} className="photo">
            <Image fluid className="myPhoto" src={myPhoto} alt="elashry" />
          </Col>
          <Col xs={9} className="paragraph-col">
            <h3 className="paragraph">
              <Typewriter
                options={{
                  autoStart: true,
                  loop: true,
                  strings: [
                    'Hello,I am<h2 class="welcome" > Mohamed Elashry</h2>Freelancer Web Developer',
                    "Welcome in my portfolio website.",
                  ],
                }}
              />
            </h3>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Intro;
