import React, { useEffect, useRef, useState } from "react";
import Contact from "./contact/Contact";
import "./home.scss";
import Intro from "./intro/Intro";
import Certifications from "./certifications/Certifications";
import Portfolio from "./portfolio/Portfolio";
import Skills from "./skills/Skills";

import "./upDown.scss";
import { Image } from "react-bootstrap";
import down from "../../utils/img/caret-down-solid.svg";
import up from "../../utils/img/caret-up-solid.svg";
import { useLocation } from "react-router-dom";
const Home = ({ setActiveSection }) => {
  const location = useLocation();
  const scrollRef = useRef();
  const [scrollPosition, setScrollPosition] = useState("start");
  const sections = document.getElementsByClassName("section");

  useEffect(() => {
    const options = {
      threshold: 0.6,
    };
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setActiveSection(entry.target.getAttribute("id"));
          entry?.target?.classList?.add("active");
          return;
        } else {
          setActiveSection("");
          entry?.target?.classList?.remove("active");
        }
      });
    }, options);
    for (const section of sections) {
      observer.observe(section);
    }
  }, [sections, setActiveSection]);
  useEffect(() => {
    document
      .getElementById(location?.hash?.slice(1))
      ?.scrollIntoView({ behavior: "smooth" });
  }, [location]);

  const detectScroll = () => {
    scrollRef.current.scrollTop === 0
      ? setScrollPosition("start")
      : scrollRef.current.scrollTop + scrollRef.current.offsetHeight >=
        scrollRef?.current?.scrollHeight
      ? setScrollPosition("end")
      : setScrollPosition("scrolling");
  };
  const scroll = (e, by) => {
    e.preventDefault();
    scrollRef.current.scrollBy({
      top: by,
      left: 0,
      behavior: "smooth",
    });
  };
  return (
    <div
      className="home"
      ref={scrollRef}
      onScrollCapture={() => detectScroll()}
    >
      <Intro />
      <Portfolio />
      <Certifications />
      <Skills />
      <Contact />
      <div className="upDown">
        {scrollPosition !== "start" && (
          <Image
            className="up"
            src={up}
            alt="up"
            onClick={(e) => scroll(e, -100)}
          />
        )}
        {scrollPosition !== "end" && (
          <Image
            className="down"
            src={down}
            alt="down"
            onClick={(e) => scroll(e, 100)}
          />
        )}
      </div>
    </div>
  );
};

export default Home;
