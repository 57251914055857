import cvBuilderImg from "../../../utils/img/websites/cvBuilder.jpg";
import xoChatImg from "../../../utils/img/websites/xoChat.jpg";

const portfolioItemsDetails = [
  {
    name: "cvBuilder",
    img: cvBuilderImg,
    about:
      "cvBuilder is a web application helps to build your resume and download it easly.",
    details: [
      "login/signup",
      "landing page",
      "form page",
      "final cv preview",
      "downloading",
    ],
    technologies: {
      frontEnd: [
        "React",
        "react-router-dom",
        "axios",
        "bootstrap",
        "redux",
        "react-redux",
        "popper.js",
      ],
      backEnd: [
        "node.js",
        "express",
        "body-parser",
        "cors",
        "mongoose",
        "multer",
        "node-fetch",
        "nodemailer",
        "puppeteer",
      ],
    },
    address: "https://build-cv.netlify.app/",
  },
  {
    name: "xoChat",
    img: xoChatImg,
    about:
      "xo-chat app is a Realtime chat web application with voice calls and video calls.",
    details: [
      "login/signup ",
      "profile page",
      "add/remove/response friend",
      "notifications",
      "voice call",
      "video call",
      "chatting",
      "search",
    ],
    technologies: {
      frontEnd: [
        "React",
        "react-router-dom",
        "axios",
        "simple-peer",
        "redux",
        "react-redux",
        "material-ui",
        "socket.io-client",
      ],
      backEnd: [
        "node.js",
        "express",
        "body-parser",
        "cors",
        "mongoose",
        "multer",
        "nodemailer",
        "socket.io",
      ],
    },
    address: "https://xo-chat.pages.dev/",
  },
];

export const getData = (name) => {
  return portfolioItemsDetails.find((item) => item.name === name);
};
