import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./skills.scss";

const Skills = () => {
  const mySkills = [
    "Html",
    "Css",
    "Sass",
    "Bootstrap",
    "Javascript",
    "JQuery",
    "React",
    "Node.js",
    "Mongodb",
  ];
  return (
    <div className="section skills " id="skills">
      <Container fluid className="section-card skills-card">
        <h1 className="topic" style={{ animationDelay: ".5s" }}>
          skills
        </h1>
        <Row className="mySkills">
          {mySkills.map((skill) => (
            <Col
              className="skill-col"
              xs={6}
              lg={4}
              key={skill}
              style={{
                animationDelay: mySkills.indexOf(skill) / 2 + 0.5 + "s",
              }}
            >
              <p className="skill">{skill}</p>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default Skills;
